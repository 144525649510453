import { Document } from '@contentful/rich-text-types';
import { FormType, OpenpriseFieldKey } from '@rippling/utils';

import { PopupModalProps } from './components/molecules/PopupModal';
import {
  AwardName, FlagName, IconName, LogoName
} from './icons';

export enum Theme {
  DarkPlum = 'darkPlum',
  Grey = 'grey',
  Plum = 'plum',
  White = 'white'
}

export enum TextAlignment {
  Center = 'Center',
  Left = 'Left'
}

export enum IconStyle {
  App = 'App',
  Badge = 'Badge',
  Default = 'Default'
}

export enum ImagePosition {
  Left = 'Left',
  Right = 'Right'
}

export enum ImageStyle {
  Default = 'Default',
  Tablet = 'Tablet'
}

export enum FormFieldType {
  Email = 'email',
  Hidden = 'hidden',
  Number = 'number',
  Select = 'select',
  Tel = 'tel',
  Text = 'text',
  TextArea = 'textarea'
}

export enum GroupContainerType {
  Accordion = 'Accordion',
  InfiniteScroller = 'Infinite Scroller',
  NumberList = 'Number List',
  Stats = 'Stats'
}

export enum CenteredHighlightContentType {
  Asset = 'Asset',
  Tabs = 'Tabs',
  Wistia = 'Wistia'
}

export enum BackgroundPlacement {
  Bottom = 'Bottom',
  Left = 'Left',
  Right = 'Right',
  Tile = 'Tile',
  Top = 'Top'
}

export interface BaseComponent {
  onSubmit?: (args: {
    formData: Record<OpenpriseFieldKey, string>;
    formType?: FormType;
    thankYouUrl?: string;
  }) => void;
  redirect?: (url: string) => void;
}

export interface ComponentWithPadding {
  applyPaddingToAllSides?: boolean;
  padding?: string; // 'default'
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;
  paddingTop?: string;
  useDefaultPadding?: boolean;
}

export interface ComponentWithMargin {
  applyMarginToAllSides?: boolean;
  margin?: string; // 'default'
  marginBottom?: string;
  marginLeft?: string; // 'auto'
  marginRight?: string; // 'auto'
  marginTop?: string;
  useDefaultMargin?: boolean;
}

export type BaseComponentWithMarginAndPadding = BaseComponent &
  ComponentWithMargin &
  ComponentWithPadding;

export interface ImageFieldValue {
  alt: string;
  src: string;
}

export enum SurfaceLevel {
  L0 = 'L0',
  L1 = 'L1'
}

export interface CfSys {
  contentType: { sys: { id: string; } };
  createdAt: string;
  firstPublishedAt: null | string;
  id: string;
  publishedAt: null | string;
  updatedAt: string;
}

export interface BaseCfComponent {
  sys: CfSys;
  className?: string;
}

export interface CfLink extends BaseCfComponent {
  fields: {
    // Removed in BE
    internalPage?: never;

    // Added in BE
    internalUrl?: string;
    newTab?: boolean;
    popupModal?: PopupModalProps;
    text: string;
    url: string;
  };
}

// Margin & Padding
export interface CfSpacingData extends BaseCfComponent {
  fields: {
    data: {
      name: string;
      value: string;
    };
    id: string;
    systemTitle: string;
  };
}

// Theme
export interface CfThemeData extends BaseCfComponent {
  fields: {
    data: {
      name: string;
      value: string;
    };
    id: string;
    systemTitle: string;
  };
}

// [Component] Item
export interface CfItem extends BaseCfComponent {
  fields: {
    award?: AwardName;
    body?: Document;
    cta?: CfLink;
    icon?: IconKey;
    iconStyle?: IconStyle;
    image?: CfAsset;
    imageStyle?: ImageStyle;
    logo?: LogoName;
    systemTitle: string;
    title: string;
  };
}

export interface CfComponentFieldsWithMarginAndPadding {
  marginBottom?: CfSpacingData;
  marginTop?: CfSpacingData;
  paddingBottom?: CfSpacingData;
  paddingTop?: CfSpacingData;
}

export interface CfRepeatableItem {
  id: string;
  key: string;
  value: string;
}

export interface CfAsset extends BaseCfComponent {
  fields: {
    description?: string;
    file: {
      contentType: string;
      details: {
        image: {
          height: number;
          width: number;
        };
        size: number;
      };
      fileName: string;
      url: string;
    };
    title: string;
  };
}

export interface CfLocale extends BaseCfComponent {
  fields: {
    addPrefixThe?: boolean;
    country: string;
    flag: CfAsset;
    languageCode: string;
    mainNavigation: boolean;
  };
}

export enum FooterStyle {
  Condensed = 'Condensed',
  Default = 'Default',
  Hidden = 'Hidden'
}

export interface CfPageMetadata extends BaseCfComponent {
  fields: {
    footerStyle: FooterStyle;
    headerStyle?: HeaderStyle;
    hideDemoButton?: boolean;
    hideHeader?: boolean;
    hideLocaleSelector?: boolean;
    hideLoginButton?: boolean;
    isIndexed: boolean;
    localeBanner?: CfLocale[];
    publishedLocales?: CfLocale[];
    seoDescription: string;
    seoImage: CfAsset;
    seoTitle: string;
    trackingId?: string;
  };
}

export interface CfDataTheme extends BaseCfComponent {
  fields: {
    data: {
      // Ex: primary-plum
      name: string;

      // HEX value
      value: string;
    };
  };

  // Ex: theme-primary-plum
  id: string;
}

export interface WistiaVideo {
  duration: number;
  hashed_id: string;
  id: number;
  thumbnail: {
    height: number;
    url: string;
    width: number;
  };
}

export interface CfWistiaVideo { items: WistiaVideo[] }

export interface CfPerson extends BaseCfComponent {
  fields: {
    bio: string;
    company: string;
    fullName: string;
    jobTitle: string;
    profilePicture?: CfAsset;
    slug: string;
    socialUrLs?: string[];
  };
}

export interface CfGroupContainer extends BaseCfComponent {
  fields: {
    config?: Record<string, unknown>;
    items?: CfItem[];
    scrollingItems?: CfItem[];
    systemTitle: string;
    type: GroupContainerType;
  }
}

export enum CalculatorVariant {
  MINI = 'Mini',
  ONE_COLUMN = '1 Column',
  TWO_COLUMN = '2 Column'
}

export type IconKey = FlagName | IconName;

export enum HeaderStyle {
  Condensed = 'Condensed',
  Default = 'Default',
  Hidden = 'Hidden',
  RipplingHCM = 'Rippling HCM',
  RipplingIT = 'Rippling IT',
  RipplingSpend = 'Rippling Spend'
}
